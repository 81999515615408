import { Box, Flex, Heading, LinkBox, LinkOverlay, useDisclosure } from "@chakra-ui/react";
import { useRouter } from "next/router";
import SearchInput from "../../search/components/SearchInput";
import { SearchPageModal } from "@/search/components/SearchPageModal";
export default function Hero() {
  const router = useRouter();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const handleSearchInputClick = () => {
    onOpen();
    router.push("/search", undefined, {
      shallow: true
    });
  };
  return <Box height={{
    base: "250px",
    md: "468px",
    lg: "580px",
    xl: "640px"
  }} bgColor="var(--chakra-colors-snow-blue-hero)" maxHeight="640px" as="section" display="flex" flexDirection="column" justifyContent="center" alignItems="center" data-sentry-element="Box" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      <Heading as="h1" variant="heroTitle" fontWeight="bold" fontSize={{
      base: "24px",
      lg: "48px"
    }} data-sentry-element="Heading" data-sentry-source-file="Hero.tsx">
        Top Extracurriculars
      </Heading>
      <Flex mt={2} data-sentry-element="Flex" data-sentry-source-file="Hero.tsx">
        <Heading variant="heroSubtitle" fontWeight="hairline" fontSize={{
        base: "16px",
        lg: "24px"
      }} data-sentry-element="Heading" data-sentry-source-file="Hero.tsx">
          Curated by College Consultants
        </Heading>
      </Flex>
      <SearchPageModal onClose={onClose} isOpen={isOpen} data-sentry-element="SearchPageModal" data-sentry-source-file="Hero.tsx" />
      <LinkBox width={{
      base: "300px",
      lg: "600px"
    }} mt={{
      base: 4,
      lg: 8
    }} data-sentry-element="LinkBox" data-sentry-source-file="Hero.tsx">
        <LinkOverlay cursor="pointer" onClick={handleSearchInputClick} data-sentry-element="LinkOverlay" data-sentry-source-file="Hero.tsx">
          <SearchInput goBack={false} data-sentry-element="SearchInput" data-sentry-source-file="Hero.tsx" />
        </LinkOverlay>
      </LinkBox>
    </Box>;
}
import { BoxProps, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { FeaturedListItem } from "./FeaturedListItem";
import { useFeaturedLists } from "../hooks/useFeaturedLists";
import { FeaturedList } from "../types/FeaturedList";
import { SectionedGrid } from "@/ui/sectionedGrid/SectionedGrid";
interface FeaturedListsProps {
  maxWidth?: BoxProps["maxWidth"];
  disableArrowPlaceholders?: boolean;
  containerProps?: BoxProps;
  titleContent?: React.ComponentType | React.ReactNode;
}
export const FeaturedLists = (props: FeaturedListsProps) => {
  const {
    titleContent,
    ...otherProps
  } = props;
  const {
    featuredLists,
    loading
  } = useFeaturedLists();
  const itemWidth = useBreakpointValue({
    base: 240,
    md: 300
  }) ?? 300;
  const renderFeaturedList = (list: FeaturedList) => {
    return <FeaturedListItem key={list.identifier} list={list} data-sentry-element="FeaturedListItem" data-sentry-component="renderFeaturedList" data-sentry-source-file="FeaturedLists.tsx" />;
  };
  if (featuredLists.length === 0 && !loading) {
    return null;
  }
  return <>
      {typeof titleContent === "function" ? React.createElement(titleContent) : titleContent}
      <SectionedGrid data={featuredLists} loading={loading} maxSectionSize={4} itemWidth={itemWidth} renderItem={renderFeaturedList} {...otherProps} data-sentry-element="SectionedGrid" data-sentry-source-file="FeaturedLists.tsx" />
    </>;
};
import { useGetFeaturedListsQuery } from "@/generated/graphql"
import graphqlRequestClient from "@/graphql/client"

export const useFeaturedLists = () => {
  const { data, isLoading, error } =
    useGetFeaturedListsQuery(graphqlRequestClient)

  return {
    featuredLists: data?.featuredLists || [],
    loading: isLoading,
    error,
  }
}

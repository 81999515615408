import { Box, Container, Flex } from "@chakra-ui/react";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import SnowdayHelp from "./components/SnowdayHelp";
import SnowdayIntro from "./components/SnowdayIntro";
import SnowdayMission from "./components/SnowdayMission";
import { TopSearches } from "../../search/components/topSearch/TopSearches";
import ComponentHeading from "../../ui/common/ComponentHeading";
import SnowdayIcons from "../../ui/icons/SnowdayIcons";
import { useAnalytics } from "@/features/analytics/components/AnalyticsProvider";
import { FeaturedLists } from "@/features/featuredLists/components/FeaturedLists";
import { AskSnowdayBlock } from "@/ui/askSnowday/AskSnowdayBlock";
import Hero from "@/ui/common/Hero";
const HomeScreen = () => {
  const {
    t
  } = useTranslation("titles");
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.track("Landing page visited", {
      Page: "Home"
    });
  }, []);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="screen.tsx">
        <title>{`Snowday | ${t("page-titles.home")}`}</title>
      </Head>

      <Flex as="main" minHeight={{
      base: "calc(100vh - 48px)",
      md: "calc(100vh - 56px)",
      lg: "calc(100vh - 64px)"
    }} flexDirection="column" data-sentry-element="Flex" data-sentry-source-file="screen.tsx">
        <Hero data-sentry-element="Hero" data-sentry-source-file="screen.tsx" />
        <TopSearches data-sentry-element="TopSearches" data-sentry-source-file="screen.tsx" />
        <Box as="section" mt={{
        base: 4,
        lg: 8
      }} mb={{
        base: 10
      }} pl={{
        base: 3,
        md: 0
      }} justifyContent="center" data-sentry-element="Box" data-sentry-source-file="screen.tsx">
          <FeaturedLists titleContent={() => {
          return <Container px={{
            base: 0,
            md: 20,
            lg: 0
          }} maxWidth={{
            md: "100%",
            lg: "720px"
          }}>
                  <ComponentHeading icon={<SnowdayIcons name="Pin" color="snow.blue" boxSize={7} mr={-1} />} title="Featured Lists" color="snow.blue-medium" />
                </Container>;
        }} data-sentry-element="FeaturedLists" data-sentry-source-file="screen.tsx" />
        </Box>
        <SnowdayIntro data-sentry-element="SnowdayIntro" data-sentry-source-file="screen.tsx" />
        <SnowdayMission data-sentry-element="SnowdayMission" data-sentry-source-file="screen.tsx" />
        <AskSnowdayBlock light data-sentry-element="AskSnowdayBlock" data-sentry-source-file="screen.tsx" />
        <SnowdayHelp data-sentry-element="SnowdayHelp" data-sentry-source-file="screen.tsx" />
      </Flex>
    </>;
};
export default HomeScreen;
import { Avatar, HStack, Text, VStack } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { GetFeaturedListsQuery } from "../../../generated/graphql";
interface FeaturedListProps {
  list: GetFeaturedListsQuery["featuredLists"][number];
}
export const FeaturedListItem = ({
  list
}: FeaturedListProps) => {
  const router = useRouter();
  const handleClick = () => {
    router.push(`/lists/${list.identifier}`);
  };
  return <VStack alignItems="flex-start" px={5} pt={3} pb={5} onClick={handleClick} data-sentry-element="VStack" data-sentry-component="FeaturedListItem" data-sentry-source-file="FeaturedListItem.tsx">
      <Text style={{
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical"
    }} display="-webkit-box" textOverflow="ellipsis" overflow="hidden" color="snow.blue-dark" fontSize="18px" fontWeight={700} mb={2} data-sentry-element="Text" data-sentry-source-file="FeaturedListItem.tsx">
        {list.name}
      </Text>
      <HStack alignItems="flex-start" data-sentry-element="HStack" data-sentry-source-file="FeaturedListItem.tsx">
        <Avatar src={list.authorDisplayAvatarUrl ?? undefined} size="sm" mr={0.5} data-sentry-element="Avatar" data-sentry-source-file="FeaturedListItem.tsx" />
        <VStack alignItems="flex-start" data-sentry-element="VStack" data-sentry-source-file="FeaturedListItem.tsx">
          <Text style={{
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical"
        }} display="-webkit-box" textOverflow="ellipsis" overflow="hidden" color="snow.blue-medium" fontSize="12px" fontWeight={600} mt={-1} mb={-2} data-sentry-element="Text" data-sentry-source-file="FeaturedListItem.tsx">
            {list.authorDisplayName ?? list.author.name}
          </Text>
          <Text style={{
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical"
        }} display="-webkit-box" textOverflow="ellipsis" overflow="hidden" color="snow.blue-medium" fontSize="10px" fontStyle="italic" data-sentry-element="Text" data-sentry-source-file="FeaturedListItem.tsx">
            {list.authorDisplayTitle}
          </Text>
        </VStack>
      </HStack>
    </VStack>;
};